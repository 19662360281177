<template>
  <div class="animated fadeIn">
   
    <b-row>
      <b-col>
        <TableWrapper
          :ref="model.name"
          :model="model"
          :parent-id="product.id"
          actions-mode="inline"
          :columns="tableColumns"
          :read-only="false"
          :filter-by-column="true"
          @inserted="onTableInsert"
          @updated="onTableChange"
        >
        </TableWrapper>
      </b-col>
    </b-row>
    <b-row style="margin-bottom: 10em;"> </b-row>
  </div>
</template>

<script>
import model from '@/models/SalesAdministrative/product-custom-prices.model.js'

import TableWrapper from '@/components/DataView/TableWrapper'

export default {
  props: {
    product: {
      type: Object,

      required: true
    }
  },
  name: 'ProductCustomPrices',
  components: {TableWrapper},
  data: function () {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      model: model.productCustomPrices,
      filters: ['Distributor', 'State'],
      tableColumns: ['ID', 'Distributor', 'Market', 'Price', 'Valid From'],
      data: {}
    }
  },
  created () {},
  mounted () {

    console.log('ProductCustomPrices mounted, product:', this.product)  
    const productEntity = this.model.entities.find(entity => entity.name === 'Product_ID')
    const priceEntity = this.model.entities.find(entity => entity.name === 'Price')
   
    
      priceEntity.defaultValue = this.product['Unit Price'] ?? this.product.unit_price
   
      productEntity.defaultValue = this.product['ID'] ?? this.product.id   
  },
  methods: {
    onTableInsert () {},
    onTableChange () {}
  },
  watch: {}
}
</script>

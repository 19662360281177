
import moment from 'moment'
import productServices from '../../services/SalesAdministrative/products.service'
import dictionaryServices from '../../services/dictionary.service'


const rowValidator = row => {

  console.log('rowValidator', row)
  if (!row['Distributor']?.length && !row['Market']?.length) {
    return { result: false, message: 'Distributor or Market is required' };
  }

  if (!row['Price'] || row['Price'] <= 0) {
    return { result: false, message: 'Price must be greater than zero' };
  }

  if (!row['Valid From']?.length) {
    return { result: false, message: 'Valid From date is required' };
  }

  return { result: true };
};

export default {
  productCustomPrices: {
    name: 'product_custom_prices',
    entities: [
      {
        name: 'Product_ID',
        type: 'number',
        readonly: true,
        required: true,
        
      },
      {
        name: 'ID',
        type: 'string',
        readonly: true
      },

      {
        name: 'Distributor',
        type: 'dropdown',
        readonly: false,
        optionsService: dictionaryServices.fetchDistributors,
        
      },
      {
        name: 'Market',
        type: 'dropdown',
        readonly: false,
        optionsService: dictionaryServices.fetchMarkets
      },

      {
        name: 'Price',
        type: 'number',
        readonly: false
      },
      /*
      {
        name: 'Currency',
        type: 'dropdown',
        readonly: false,
        optionsService: dictionaryServices.fetchCurrencies,
        defaultValue: {id: 120, label: 'USA, Dollars'}
      },
      */
      {
        name: 'Valid From',
        type: 'date',
        readonly: false,
        defaultValue: moment().format('YYYY-MM-DD')
      },
      {
        name: 'Valid To',
        type: 'date',
        readonly: false
      }
    ],
    services: {
      fetchRecord: () => {},
      fetchData: productServices.fetchProductCustomPrices,
      createRecord: () => {},
      saveRecord: productServices.saveProductCustomPrice,
      deleteRecord: productServices.deleteProductCustomPrice
    },
    actions: {
      Create: {
        validator: () => true
      },
      Delete: {
        validator: () => true
      }
    },
    rowValidator
  }
}
